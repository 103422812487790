<template>
  <div class="flex flex-wrap">
    <div class="masonry-content p-5 relative w-full">
      <div class="flex flex-col">
        <div class="flex flex-wrap -my-2 -mx-5">
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">
              Address Type
              <span class="text-red-500">*</span>
            </label>
            <select v-model="address.type" class="bge-input bge-select rounded">
              <option v-for="term in address_types" :key="term.data_master_id" :value="term.value">
                {{ term.name }}
              </option>
            </select>
          </div>
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Postcode</label>
            <div class="flex w-full relative">
              <input class="bge-input bge-input-spacing rounded flex-1" type="text" v-model="address.postcode" />
              <button
                  type="button"
                  @click="findAddress"
                  class="relative overflow-hidden ml-5 flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-3 py-1"
              >
                <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                <span>Find Address</span>
              </button>
              <div v-if="selectAddressState == 'Results'" class="mt-5 pl-5 absolute bg-white shadow-2xl rounded-lg z-20 p-4 flex flex-col">
                <label class="uppercase font-semibold text-sm">Select Address</label>
                <div class="inline-block relative">
                  <select v-model="selectedAddress" class="bge-input bge-select rounded">
                    <template v-for="(address, i) in availableAddressData.addresses">
                      <option :key="i" :value="address">{{ address.formatted_address }}</option>
                    </template>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <div class="text-right">
                  <button
                      @click="selectAddress"
                      type="button"
                      class="mt-3 inline-flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-3 py-1"
                  >
                    <svg
                        class="stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                    >
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                      <circle cx="12" cy="10" r="3" />
                    </svg>
                    <span>Select Address</span>
                  </button>
                </div>
              </div>
              <div class="fixed inset-0 z-10" @click="selectAddressState = 'Idle'" v-if="selectAddressState == 'Results'"></div>
            </div>
          </div>
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Address 1</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="address.address1" />
          </div>
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Address 2</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="address.address2" />
          </div>
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Address 3</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="address.address3" />
          </div>
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">City</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="address.city" />
          </div>
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">County</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="address.county" />
          </div>
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Country</label>
            <div class="inline-block relative">
              <select v-model="address.country" class="bge-input bge-select rounded">
                <optgroup label="Frequently Used">
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Kenya">Kenya</option>
                  <option value="England">England</option>
                  <option value="Scotland">Scotland</option>
                  <option value="Wales">Wales</option>
                  <option value="Northern Ireland">Northern Ireland</option>
                  <option value="Republic of Ireland">Republic of Ireland</option>
                </optgroup>
                <optgroup label="All Countries">
                  <template v-for="(country, i) in countries">
                    <option :key="i" :value="country">{{ country }}</option>
                  </template>
                </optgroup>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-5">
        <AppButton color="blue" @click="isEditMode ? updateAddress() : createAddress()" label="Save" :icon="true">
          <template v-slot:icon>
            <Icon iconType="save" class="mr-2" />
          </template>
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";

export default {
  name: "AddressManager",
  mixins: [notificationMixin],
  components: {
    AppButton,
    Icon,
  },
  props: {
    initial_address: {
      type: Object,
      default: () => ({}),
    },
    contact_id: {
      type: String,
      required: true,
    },
    address_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      address: {},
      selectAddressState: "Idle",
      availableAddressData: [],
      selectedAddress: "",
      address_types: [],
      isEditMode: !!this.address_id,
    };
  },
  async mounted() {
    await this.fetchDataMasterAddressTypes();

    if (this.initial_address) {
      this.address = { ...this.initial_address };
    }
  },
  methods: {
    async fetchDataMasterAddressTypes() {
      try {
        const response = await this.DatamasterService.getDataMasters(
            "?noPaging=true&type=AddressType&is_active=true&orderBy=order&order=1"
        );
        this.address_types = response.map((item) => ({
          data_master_id: item.data_master_id,
          name: item.name,
          value: item.value || "",
        }));
      } catch (error) {
        console.error("Error fetching data master address types:", error);
      }
    },
    findAddress: async function() {
      try {
        if (this.address.postcode) {
          this.selectAddressState = "Loading";
          let result = await this.SalesProspectService.findAddressFromPostcode(this.address.postcode);
          this.availableAddressData = result.data;
          this.selectAddressState = "Results";
        }
      } catch (error) {
        console.error(error);
        this.selectAddressState = "Idle";
        this.notifyError("Error finding address.");
      }
    },
    selectAddress: function() {
      if (this.selectedAddress) {
        this.selectAddressState = "Idle";
        this.address.postcode = this.availableAddressData.postcode;
        this.address.address1 = this.selectedAddress.line_1;
        this.address.address2 = this.selectedAddress.line_2;
        this.address.address3 = this.selectedAddress.line_3;
        this.address.city = this.selectedAddress.town_or_city;
        this.address.county = this.selectedAddress.county;
        this.address.country = this.selectedAddress.country;
      }
    },
    validateRequiredFields() {
      const requiredFields = [
        { field: this.address.type, name: "Address Type" },
        { field: this.address.postcode, name: "Postcode" },
        { field: this.address.address1, name: "Address 1" },
        { field: this.address.city, name: "City" },
      ];

      for (const field of requiredFields) {
        if (!field.field) {
          this.notifyError(`${field.name} is required.`);
          return false;
        }
      }
      return true;
    },
    createAddress: async function() {
      if (!this.validateRequiredFields()) {
        return;
      }

      debugger;

      try {
        await this.ContactService.createAddress(this.address, this.contact_id);
        this.notifySuccess("Address created successfully");
        this.$emit("refreshContact");
      } catch (error) {
        this.notifyError("Couldn't create Address");
        console.error(error);
      }
    },
    updateAddress: async function() {
      if (!this.validateRequiredFields()) {
        return;
      }

      try {
        await this.ContactService.updateAddress(this.contact_id, this.address_id,this.address);
        this.$emit("refreshContact");
        this.notifySuccess("Address updated successfully");

      } catch (error) {
        this.notifyError("Couldn't update Address");
        console.error(error);
      }
    },
  },
};
</script>
